
@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.sharedArticlesBody{
    display: flex;
    flex-direction: column;
    
    @include mobile{
        align-items: center;
        height: max-content;
        margin: 3rem auto 5rem auto
    }

    &__header{
        font-family:'Montserrat-bold';
        @include mobile{
            font-size: larger;
        }
    }
  
    @include tablet{
        height: 72vh;
        margin: 1rem;
    }
    @include desktop{
        margin: 6rem auto auto auto;
    }

    &__headerWrapper{
        display: flex;
        flex-direction: row;
        gap: 0.4rem;
        @include mobile{
            justify-content: center;
        }
    }
    &__icon{
        width: 4%;
        @include mobile{
            width: 9%;
        }
    }
}
.articleContainer{
 
    @include mobile{
        margin:0 0 1.5rem 0;
        width: 90%;
        background-color: #ffffff;
        border-radius: 24px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
        padding: 0 1rem 1rem 1rem;
    }
    
    @include tablet{
        display: none;
    }
    @include desktop{
        display: none;
    }

}

.article{
    &__wrapper{
        @include mobile{
            font-family:'Montserrat-regular'; 
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
       
    }

    &__heading{
        @include mobile{
            font-family:'Montserrat-bold';
            font-size: 15px; 
            font-weight: 700;
        }
        @include tablet{
            display: none;
        }
        @include desktop{
            display: none;
        }
    }

    &__data{
        @include mobile{
            font-family:'Montserrat-regular'; 
            font-size: 14px;
        }
        &--articleTitle{
            text-overflow:ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 55%;
            @include tablet{
                width: 21%;
                display: flex
            }
        }
    }
}

.SharedtabletDekstopView{
    @include mobile{
        display: none;
    }
    padding: 1rem 0 2rem 0;
    font-family:'Montserrat-regular';

    background-color: #fafafa;
    border-radius: 2px;
    box-shadow: 0px 1px 12px rgba(3,3,3,0.08);

      
    &__heading{
        font-size: 14px;
        font-family: "Montserrat-bold";
        font-weight: 700;
        border-bottom: 2px solid;
        padding: 1rem;
        text-align: center;

    @include desktop{
        font-size: 21px;

    }
    }
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &__titles{

        @include mobile{
            text-overflow:ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 25%;
        }
       
        display: flex;
        flex-direction: column;
        gap: 30px;
        background-color: #eeeeee;
        &--data{
            font-size: 15px;
            @include tablet{
                font-size: 12px;
                width: 76%;

            }
        }
        @include desktop{
            width: 53%;
            font-size: 17px;
        }


    }
    &__category{
        display: flex;
        flex-direction: column;
        gap: 13px;
        display: flex;
        &--data{
            font-size: 14px;
            gap: 26px;
        }
        @include tablet{
            gap:28px;
        }
        @include desktop{
            font-size: 24px;
            gap: 42px;
        }
    }

    &__sharedWith{
        display: flex;
        flex-direction: column;
        gap: 13px;
        display: flex;
        background-color: #eeeeee;
        &--data{
            font-size: 15px;
        }
        @include tablet{
            gap: 24px;
        }
        @include desktop{
            gap: 50px;
        }
    }

    &__date{
        display: flex;
        flex-direction: column;
        gap: 13px;
        display: flex;
        &--data{
            font-size: 15px;
        }
        @include tablet{
            gap: 24px;
            width: 36%;
            text-align: -webkit-center;
        }
        @include desktop{
            gap: 47px;
            font-size: 20px;
        }
    }

    
}


.summaryContainer{
    @include desktop{
        display: flex;
        flex-direction: row;

    }
}
.desktopLandscape{
    @include desktop{
        display: flex;
        flex-direction: row;
    }
}
.SummaryDesktopnavbar{
    @include mobile{
        display: none;
    }
    @include tablet{
        display: none;
    }
    @include desktop{
        height: 105vh;
        width: min-content;
        background-color: $green-dark;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        &__wrapper{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding: 0 1rem 0 1rem;
            align-items: center;
            justify-content: space-between;
            
        }
        &__link{
            text-decoration: none;
            display: flex;
            align-items: center;
            padding-top: 7px;
            display: flex;
            flex-direction: column;
            
    
        }
        &__icon{
            @include mobile{
                width: 12vw;
            }
            @include tablet{
                width: 5vw;
            }
            @include desktop{
                width: 2vw;
            }
        }
    
        &__header{
            font-family: 'Montserrat-regular';
            color:$black;
            font-size: 12px;
    
            @include tablet{
                font-size: 24px;
            }
        }
    }
}

