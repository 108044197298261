@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.navbar{
    background-color: $green-dark;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    

    @include mobile{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 8vh;
    }
    @include tablet{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 10vh;
    }
    @include desktop{
        display: none;
    }

    &__wrapper{
        @include mobile{
            display: flex;
            flex-direction: row;
            gap: 6rem;
            padding: 0 0.5rem 0 0.5rem;
            align-items: center;
        }
        @include tablet{
            display: flex;
            flex-direction: row;
            gap: 6rem;
            padding: 0 50px 0 19px;
            align-items: center;
            justify-content: space-between;
        }
    }
    &__link{
        text-decoration: none;
        @include mobile{
            display: flex;
            align-items: center;
            margin:auto;
            padding-top: 7px;
        }
        @include tablet{
            display: flex;
            align-items: center;
            padding-top: 7px;
        }
        @include desktop{
            display: flex;
            align-items: center;
            margin:auto;
            padding-top: 7px;
        }

    }
    &__icon{
        @include mobile{
            width: 12vw;
        }
        @include tablet{
            width: 5vw;
        }
        @include desktop{
            width: 5vw;
        }
    }

    &__header{
        font-family: 'Montserrat-regular';
        color:$black;
        font-size: 19px;

        @include tablet{
            font-size: 24px;
        }
    }
}

.user{

    display: flex;
    flex-direction: column;
    width: 50%;
    @include mobile{
        gap: 3px;
    }

    @include tablet{
        display: flex;
        flex-direction: row;
        width: 21%;
        align-items:self-end;
    }
    @include desktop{
        display: flex;
        flex-direction: row;
        width: 15%;
        align-items: baseline;
        padding-right: 1rem;
        
    }

    &__wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 5px;
        @include mobile{
            gap: 11px;
        }
        @include tablet{
            align-items: center;
        }

        &--profile{
            @include desktop{
                align-items: flex-end;
                display: flex;
                align-items: center;
            }
        }

        &--login{
            display: flex;
            align-items: end;
            flex-direction: row;
            gap:0;
            text-decoration: none;

            @include tablet{
                justify-content: right;
            }
            @include desktop{
                justify-content: right;
            }
        }
    }

    &__loginTitle{
        font-family:'Montserrat-bold';
        font-size: 12px;
        color: $black;
    }
    &__name{
        color: $white;
        font-family:'Montserrat-bold';
        font-size: 20px;
        @include tablet{
            color: $black;
            font-size: 15px;
        }

        @include desktop{
            color: $black;
            font-size: 26px;
        }
    }
    &__person{
       @include mobile{
        display: none;
       }
       @include tablet{
        width: 40%;
       }
       @include desktop{
        width: 30%;
       }
    }

    &__icons{
        @include mobile{
            width:5vw;
        }
        @include tablet{
            width: 2.5vw;
        }
        @include desktop{
            width: 2vw;
        }

        &--login{
            @include tablet{
                width: 5%;
            }
            @include desktop{
                width: 5%;
            }
        }
    }

}
