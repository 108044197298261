@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;


.mainAnimate{
    @include mobile{
        padding: 3rem 1rem 3rem 1rem;
        height: 66vh;
        border: none;

    }
    @include tablet{
        margin: 5rem auto 5rem auto;
        width: 50%;
        background-color: #ffffff;
        border-radius: 24px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
        animation: extend ease-in-out 2s forwards;
    }

    @include desktop{
        margin: 5rem auto 5rem auto;
        width: 50%;
        background-color: #ffffff;
        border-radius: 24px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
        animation: extendDesktop ease-in-out 2s forwards;
    }

        @keyframes extend {
            0% {
                height: 0vh;
            }
            50% {
                height: 50vh;
            }
            100% {
                height: 15vh;
                width: 40%
            }
        }

        @keyframes extendDesktop {
            0% {
                height: 0vh;
            }
            50% {
                height: 50vh;
            }
            100% {
                height: 22vh;
                width: 40%
            }
        }


    &__imageWrapperAnimate{
        text-align: center;
        position: relative;
        &--image{
            @include mobile{
                width: 40%;
            }
            @include tablet{
                width: 25%;
            }
            @include desktop{
                width: 25%;
            }
        }
        
        animation: moveDown 2s ease-in-out forwards, spin 5s linear 2s infinite;
    }
    @keyframes moveDown {
        0% {
            transform: translate3d(0,0,0) 
        }
        50% {
            transform: translate3d(0,70%,0)
        }
        100% {
            transform: translate3d(0,0,0)
        }
    }
    @keyframes spin {
        from {
             transform:rotate(0deg);
         }
        to {
             transform:rotate(360deg);
         }
     }
}

.desktopContainerAnimate{
    @include tablet{
        height: 66vh;
    }
    @include desktop{
        height:70vh;
    }
}