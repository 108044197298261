$mobile-breakpoint: 320px;
$tablet-breakpoint: 767px;
$desktop-breakpoint: 1279px;
$green-cyan:#45f1cb;
$green-dark:#81dabe;
$black:#030303;
$white-seashell: #fefefe;
$white: white;
$mercury: #E1E1E1;
$silver:#AFAFAF;
$ligh-grey:#f0f0f0;



