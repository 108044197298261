@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.mainBody{
    @include mobile{
        padding:0rem 1rem 0.5rem 1rem;
        height: 78vh;
    }
    @include tablet{
        padding: 0.25rem 1rem 1rem 1rem;
    }

    @include desktop{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__headerWrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    &__headerIcon{
        @include mobile{
            width: 15%;
        }
        @include tablet{
            width: 6%;
        }
        @include desktop{
            width: 6%;
        }
    }
    &__header{
        font-family: 'Montserrat-regular';
        @include mobile{
            text-align: center;
            font-size: 28px;
        }
        @include tablet{
            font-size: 20px;
        }
    }
}

.signup{

    display: flex;
    flex-direction: column;

    &__labels{
        color: $silver;
        @include mobile{
            font-size: 11px;
            padding: 0 0 6px 3px;
        }
        @include tablet{
            font-size: 14px;
            padding: 0 0 6px 3px;
        }
        @include desktop{
            font-size: 14px;
            padding: 0 0 6px 3px;
        }
    }

    &__field{
        border-radius: 9999px;
        line-height: 44px;
        border: 0;
        background-color: $ligh-grey;
        box-shadow: 0px 2px 8px rgba(0,0,0,0.12);
        margin-bottom: .625rem;
        width: 100%;
        background-repeat:no-repeat;
        background-position-x: right;
        background-position-y: center;
        background-size: 2vw;
        text-indent: .5625rem;

        @include desktop{   
            margin-bottom: 0;
        }
        &--name{
            background-image: url("../../assests//icons/badge.svg");
        }
        &--email{
            background-image: url("../../assests//icons/local_post_office.svg");
        }
        &--username{
            background-image: url("../../assests//icons/person.svg");
        }
        &--password{
            background-image: url("../../assests//icons/key.svg");
        }
        &--confirm{
            background-image: url("../../assests//icons/lock_reset.svg");
        }
            
    }

    &__field::placeholder{
        
        @include mobile{
            font-family:'Montserrat-regular';
            font-size: 10px;
            text-indent: 0.5rem;
        }
        @include tablet{
            font-family:'Montserrat-regular';
            font-size: 10px;
            text-indent: 0.5rem;
        }

    }

    &__button{
        cursor: pointer;
        background-color: $green-cyan;
        font-family:'Montserrat-regular';
        border: 0;
        border-radius: 22px;
        @include mobile{
            font-size: 12px;
            height: 5vh;
        }

        @include tablet{
            font-size: 12px;
            height: 5vh;
        }
        @include desktop{
            font-size: 18px;
            height: 5vh;
        }
    }

    &__redirectLogin{
        font-family: 'Montserrat-regular';
        color: $black;
        font-size: 12px;
        text-align: center;
        margin-top: 1rem;

        &--link{
            background: none;
            border: none;
            color: blue;
            text-decoration: underline;
            cursor: pointer;
        }

        @include desktop{
            font-size: 14px;
        }
    }
}

.signup__redirectLogin--link:hover {
    text-decoration: none;
  }


.navbarDesktop{
    background-color: $green-dark;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    

    @include mobile{
        display: none;
    }
    @include tablet{
        display: none;
    }
    @include desktop{
        display: flex;
        width: 40%;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 48.375rem;
    }

    &__link{
        text-decoration: none;
        @include mobile{
            display: none;
        }
        @include tablet{
            display: none;
        }
        @include desktop{
            display: flex;
            align-items: center;
            margin:auto;
            padding-top: 7px;
        }

    }
    &__icon{
        @include mobile{
            width: 12vw;
        }
        @include tablet{
            width: 5vw;
        }
        @include desktop{
            width: 5vw;
        }
    }

    &__header{
        font-family: 'Montserrat-regular';
        color:$black;
        font-size: 19px;

        @include tablet{
            font-size: 24px;
        }
    }
}
.formConainer-Desktop{
   
    @include desktop{
        width: 60%;
        padding: 2rem 1rem 2rem 1rem;
    }
}

.signupForm{
    display: flex;
    align-items: center;
    @include mobile{
        margin-bottom: 0.5rem;
    }

    &__errorImage{
        @include desktop{
            width: 14px;
        }
        @include tablet{
            width: 14px;
        }
        @include mobile{
            width: 10px;
        }
    }
    &__errorMessage{
        font-size: 12px;
        color: red;
    }
}