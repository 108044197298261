@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.main{
    @include mobile{
        padding: 3rem 1rem 3rem 1rem;
        height: 66vh;
        border: none;

    }
    @include tablet{
        margin: 5rem auto 5rem auto;
        width: 50%;
        background-color: #ffffff;
        border-radius: 24px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
    }

    @include desktop{
        margin: 5rem auto 5rem auto;
        width: 50%;
        background-color: #ffffff;
        border-radius: 24px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
    }

    &__imageWrapper{
        text-align: center;
        &--image{
            @include mobile{
                width: 40%;
            }
            @include tablet{
                width: 25%;
            }
            @include desktop{
                width: 25%;
            }
        }
    }

    &.animate{
        animation: fadeOut 1s forwards;
    }
}


@keyframes fadeOut{
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}



.articleForm{
    display: flex;
    flex-direction: column;
    @include tablet{
        padding: 1rem 1rem 1rem 1rem;
    }
    @include desktop{
        padding: 1rem 1rem 1rem 1rem;
    }


    &__field{
        margin-bottom: 1rem;
        border-radius: 9999px;
        line-height: 44px;
        border: 0;
        background-color: $ligh-grey;
        box-shadow: 0px 2px 8px rgba(0,0,0,0.12);
        margin-bottom: 1rem;
        width: 100%;
        text-indent: .5625rem;


        &--category{
            height: 5vh;
        }

    }
    &__field::placeholder{
        
        font-family:'Montserrat-regular';
        font-size: 10px;
        text-indent: 0.5rem;
    }

    &__labels{
        margin-bottom: 5px;
        color: $black;
        font-family:'Montserrat-regular';
        @include mobile{
            font-size: 11px;
            padding: 0 0 1px 3px;
        }
        
    }

    &__button{
        cursor: pointer;
        background-color: $black;
        color:$white;
        font-family:'Montserrat-bold';
        border: 0;
        border-radius: 22px;
        @include mobile{
            font-size: 12px;
            height: 5vh;
        }

        @include tablet{
            font-size: 12px;
            height: 5vh;
            margin-top: 10px;
        }
        @include desktop{
            font-size: 18px;
            height: 5vh;
            margin-top: 10px;
        }
    }
}
.desktopContainer{
    @include tablet{
        height: 66vh;
    }
    @include desktop{
        height:auto
    }
}


.Desktopnavbar{
    @include mobile{
        display: none;
    }
    @include tablet{
        display: none;
    }

    @include desktop{
        background-color: $green-dark;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 10vh;
    
        &__wrapper{
            display: flex;
            flex-direction: row;
            gap: 6rem;
            padding: 0 1rem 0 1rem;
            align-items: center;
            justify-content: space-between;
            
        }
        &__link{
            text-decoration: none;
            display: flex;
            align-items: center;
            padding-top: 7px;
            
    
        }
        &__icon{
            @include mobile{
                width: 12vw;
            }
            @include tablet{
                width: 5vw;
            }
            @include desktop{
                width: 5vw;
            }
        }
    
        &__header{
            font-family: 'Montserrat-regular';
            color:$black;
            font-size: 19px;
    
            @include tablet{
                font-size: 24px;
            }
        }

    }
}


.summarizeForm{
    display: flex;
    align-items: center;
    @include mobile{
        margin-bottom: 0.5rem;
    }

    &__errorImage{
        @include desktop{
            width: 14px;
        }
        @include tablet{
            width: 14px;
        }
        @include mobile{
            width: 10px;
        }
    }
    &__errorMessage{
        font-size: 12px;
        color: red;
    }
}