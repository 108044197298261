@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.modalBackground{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    top: 0;
}
.overLay{
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    position: absolute;
}
.modalContainer{
    width: 80%;
    height: 40%;
    background-color: $white-seashell;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #21c0a5;
    z-index: 3;
    align-items: center;
    animation: appear ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @include tablet{
        height: 45%;
    }
    @include desktop{
        width: 40%;
    }

    &__cancelBtn{
        display: flex;
        align-self: self-start;
        margin: 4px 0 0 4px;
        background-color: white;
        border: none;
    }
    &__title{
        margin-bottom: .625rem;
        font-family:'Montserrat-bold';
        color: #21c0a5;
    }
}

.login{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 80%;

    &__field{
        margin-bottom: 1rem;
        border-radius: 9999px;
        line-height: 32px;
        border: 0;
        background-color: $ligh-grey;
        box-shadow: 0px 2px 8px rgba(0,0,0,0.12);
        width: 100%;
        text-indent: .5625rem;

    }
    &__field::placeholder{
        
        font-family:'Montserrat-regular';
        font-size: 10px;
        text-indent: 0.5rem;
    }

    &__labels{
        color: $black;
        font-family:'Montserrat-regular';
        @include mobile{
            font-size: 11px;
        }
        
    }

    &__button{
        cursor: pointer;
        background-color: $black;
        color:$white;
        font-family:'Montserrat-bold';
        border: 0;
        border-radius: 22px;
        @include mobile{
            font-size: 12px;
            height: 5vh;
        }

        @include tablet{
            font-size: 12px;
            height: 5vh;
            margin-top: 10px;
        }
        @include desktop{
            font-size: 18px;
            height: 5vh;
            margin-top: 10px;
        }
    }

    &__redirectSignup{
        margin-top: 14px;
        color: $black;
        font-family:'Montserrat-regular';
        font-size: 12px;
    }
}

.login__redirectSignup--link {
    background: none;
    border: none;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .login__redirectSignup--link:hover {
    text-decoration: none;
  }
@keyframes appear{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}