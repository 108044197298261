@font-face{
    font-family:'Montserrat-regular';
    src: url("../../assests/fonts/Montserrat-VariableFont_wght.ttf") format("opentype");
    font-weight: 400;
}

@font-face{
    font-family:'Montserrat-thin';
    src: url("../../assests/fonts/Montserrat-VariableFont_wght.ttf") format("opentype");
    font-weight: 100;
}

@font-face{
    font-family:'Montserrat-bold';
    src: url("../../assests/fonts/Montserrat-VariableFont_wght.ttf") format("opentype");
    font-weight: 500;
}


