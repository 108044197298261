@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;


.articleModalsContainer{
    width: 50%;
    height: 18%;
    background-color: $white-seashell;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #21c0a5;
    z-index: 3;
    align-items: center;
    animation: appear ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @include tablet{
        height: 25%;
        width: 50%;
    }
    @include desktop{
        height: 21%;
    }
    @include mobile{
        height: 24%;
        width: 95%;
    }

    &__cancelBtn{
        display: flex;
        align-self: self-start;
        margin: 4px 0 0 4px;
        background-color: white;
        border: none;
    }
    &__title{
        font-size: 15px;
        margin-bottom: 0.625rem;
        font-family: "Montserrat-bold";
        color: $black;
        font-weight: 600;
        padding: 0 2rem 0 2rem;
        @include mobile{
            font-size: 17px;
        }

        @include desktop{
            font-size: 25px;
        }
        
    }

}
.save{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 80%;


    &__button{
        cursor: pointer;
        color:$white;
        font-family:'Montserrat-bold';
        border: 0;
        border-radius: 22px;
        @include mobile{
            font-size: 12px;
            height: 5vh;
        }

        @include tablet{
            font-size: 12px;
            height: 5vh;
            margin-top: 10px;
        }
        @include desktop{
            font-size: 18px;
            height: 5vh;
            margin-top: 10px;
        }

        &--yes{
            background-color: #21c0a5;;
        }
        &--no{
            background-color: red;
        }
    }

}

.share{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 80%;

    &__buttonContainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 5rem;
    }

    &__field{
        border-radius: 9999px;
        line-height: 32px;
        border: 0;
        background-color: $ligh-grey;
        box-shadow: 0px 2px 8px rgba(0,0,0,0.12);
        width: 100%;
        text-indent: .5625rem;

        @include mobile{
            margin-bottom: 8px;
        }

    }

    &__field::placeholder{
        
        font-family:'Montserrat-regular';
        font-size: 15px;
        text-indent: 0.5rem;
    }

    &__labels{
        color: $black;
        font-family: "Montserrat-bold";
        font-size: 24px;
        @include mobile{
            font-size: 11px;
        }
    }

    &__button{
        cursor: pointer;
        color:$white;
        font-family:'Montserrat-bold';
        border: 0;
        border-radius: 22px;
        @include mobile{
            font-size: 12px;
            height: 5vh;
        }

        @include tablet{
            font-size: 12px;
            height: 5vh;
            margin-top: 10px;
        }
        @include desktop{
            font-size: 18px;
            height: 5vh;
            margin-top: 10px;
        }

        &--yes{
            background-color: #21c0a5;;
            width: 40%;
        }
        &--no{
            background-color: red;
            width: 40%;
        }
    }

}
@keyframes appear{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}