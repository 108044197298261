@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;


.footer{
    background-color: $mercury;
    @include mobile{
        display: flex;
        flex-direction: column;
        padding: 0.5rem 1rem 0.5rem 1rem;
        height: max-content;
        gap: .3125rem;
    }
    @include tablet{
        padding: 0.5rem 2rem 0.5rem 2rem;
    }
    @include desktop{
        padding: 0.5rem 2rem 0.5rem 2rem;
    }
    &__brand{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        @include desktop{
            align-items:center;
        }
    }

    &__brand--name{
        font-family: 'Montserrat-regular';
        @include mobile{
            font-size: 10px;
        }
    }

    &__contact{
        display: flex;
        flex-direction: column;
        gap: .25rem;
        
    }
    &__contact--email{
        display: flex;
        gap:.3125rem;
        align-items: center;
        
    }
    &__contact--social{
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        
    }


    &__contact--header{
        font-family: 'Montserrat-regular';
        @include mobile{
            font-size: 9px;
        }
        @include tablet{
            font-size: 12px;
        }
        @include desktop{
            font-size: 12px;
        }
    }

    &__containers{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__containers--copyright{
        font-size: 10px;
        font-family: 'Montserrat-regular';
        text-align: center;
    }
}
.icons{
    
    cursor: pointer;
    @include mobile{
        width: 10px;
    }
    @include tablet{
        width: 2vw;
    }
    @include desktop{
        width: 1vw;
    }
    &__logo{
        @include mobile{
            width: 4vw;
        }
        @include tablet{
            width: 4vw;
        }
        @include desktop{
            width: 4vw;
        }
    }
}

hr{
    border-color: $silver ;
    border-block-width: 0.001px;
}