@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.summaryContainer{
    @include desktop{
        display: flex;
        flex-direction: row;

    }
}
.desktopLandscape{
    @include desktop{
        display: flex;
        flex-direction: row;
    }
}
.SummaryDesktopnavbar{
    @include mobile{
        display: none;
    }
    @include tablet{
        display: none;
    }
    @include desktop{
        height: 105vh;
        width: min-content;
        background-color: $green-dark;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        &__wrapper{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding: 0 1rem 0 1rem;
            align-items: center;
            justify-content: space-between;
            
        }
        &__link{
            text-decoration: none;
            display: flex;
            align-items: center;
            padding-top: 7px;
            display: flex;
            flex-direction: column;
            
    
        }
        &__icon{
            @include mobile{
                width: 12vw;
            }
            @include tablet{
                width: 5vw;
            }
            @include desktop{
                width: 2vw;
            }
        }
    
        &__header{
            font-family: 'Montserrat-regular';
            color:$black;
            font-size: 12px;
    
            @include tablet{
                font-size: 24px;
            }
        }
    }
}


.article{
    margin: 3rem auto 5rem auto;
    width: 85%;
    background-color: #ffffff;
    border-radius: 24px;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    padding: 0 1rem 1rem 1rem;

    &__title{
        animation: appearIn ease-in-out 9s;
    }

    @keyframes appearIn{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    @include mobile{
        animation: extendMobile ease-in 2s forwards;
    }

    @include tablet{
        height: 50vh;
        animation: extendTablet ease-in 2s forwards;
    }
    @include desktop{
        width: 50%;
    }

    @keyframes extendMobile {
        0% {
            height: 0vh;
            width: 0%;
        }
        50% {
            height: 25vh;
            width: 45%;
        }
    
        100% {
            height: max-content;
            width: 85%;
        }
    }

    @keyframes extendTablet {
        0% {
            height: 0vh;
            width: 0%;
        }
        25% {
            height: 15vh;
            width: 15%;
        }

        50% {
            height: 25vh;
            width:45%;
        }
        100% {
            height: 50vh;
            width: 85%;
        }
    }


    &__summary{
        font-family: monospace;
        font-size: 16px;
        line-height: 26px;
        animation: appearIn ease-in-out 8s;
        @include desktop{
            font-size: 20px;
            line-height: 50px;
        }
    }

    @keyframes appearIn{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    &__breakdown{
        margin: 5rem auto 5rem auto;
        width: 85%;
        background-color: $ligh-grey;
        border-radius: 24px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);


        @include tablet {
            animation:extendBreakdownDesktop ease-in-out 16s;
        }

        @include desktop{
            width: 30%;
            height: max-content;
            animation:extendBreakdownDesktop ease-in-out 9s;
        }

        @keyframes appearBreakdownDesktop {
            0%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }
        }
    }
    &__headingBox{
        display: flex;
        flex-direction: row;
        height: 57px;

        background-color: #81dabe;
        border-radius: 15px;
        box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        animation:extendBreakdownDesktop ease-in-out 9s;
    }
    &__icon{
            width: 10%;
            @include tablet{
                width: 4%;
            }
        }

    &__breakdownContent{
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__popularityContainer{
        display: flex;
        justify-content: space-between;
    }

    &__popularity{
        font-family: "Montserrat-bold";
        font-size: 12px;
        font-weight: 600;
        @include tablet{
            font-size: 15px;
        }

        &--rating{
            font-family: 'Montserrat-bold';
            font-weight: 500
        }

        &--trendImage{
            width:8%;
        }
        
        &--headingWrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            width: 67%;
        }
    }

    &__sourceContainer{
        display: flex;
        justify-content: space-between;
    }

    &__source{
        font-family: "Montserrat-bold";
        font-size: 15px;
        font-weight: 600;

        &--headingWrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            width: 67%;
        }
        &--sourceImage{
            width:8%;
        }

        &--link{
            font-family: 'Montserrat-bold';
            font-weight: 500
        }
        
    }
    &__videos{
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-family: 'Montserrat-regular';
        text-decoration: none;
        &--headingWrapper{
            display: flex;
            gap: 5px;
            flex-direction: row;
            @include tablet{
                align-items: center;
            }
            @include desktop{
                align-items: center;
            }
        }
        &--youtube{
            width: 8%;
            @include tablet{
                width: 5%;
            }
        }

        &--links{
            text-overflow:ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        &--header{
            font-family: "Montserrat-bold";
            font-size: 12px;
            font-weight: 600;    
        }
    }
    &__callToActions{
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 12px;
        gap: 1rem;

    }
    &__buttons{
        font-size: 12px;
        height: 3vh;
        width:30vw;
        cursor: pointer;
        background-color: #030303;
        color: white;
        font-family: "Montserrat-bold";
        border: 0;
        border-radius: 22px;
        background-repeat: no-repeat;
        background-position-y: center;
       
        background-position-x: 10px;
        @include tablet{
            height: 5vh;
            width: 18vw;
        }

        &--save{
            background-image: url(../../assests/icons/save.svg);
            background-repeat: no-repeat;
            background-size: 17%;
        }

        &--share{
            background-image: url(../../assests/icons/share.svg);
            background-repeat: no-repeat;
            background-size: 12%;
        }
    }
}

ul{
    padding-left: 1rem;
    margin-top: 0;
}



.userSummaryPage{

    display: flex;
    flex-direction: column;
    width: 50%;

    @include tablet{
        display: flex;
        flex-direction: row;
        width: 21%;
        align-items:self-end;
    }
    @include desktop{
        display: flex;
        flex-direction: column;
        width: 15%;
        align-items:center;
    }

    &__wrapper{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        &--profile{
            @include desktop{
                align-items: center;
                display: flex;
                flex-direction: column;
                gap: 0;
                margin-bottom: 3rem;
            }
        }

        &--login{
            display: flex;
            align-items: end;
            flex-direction: row;
            gap:0;
            text-decoration: none;
        }
    }

    &__loginTitle{
        font-family:'Montserrat-bold';
        font-size: 12px;
        color: $black;
    }
    &__name{
        color: $white;
        font-family:'Montserrat-bold';
        font-size: 20px;
        @include tablet{
            color: $black;
            font-size: 15px;
        }

        @include desktop{
            color: $black;
            font-size: 18px;
            padding:.625rem 0 0 0;
        }
    }
    &__person{
       @include mobile{
        display: none;
       }
       @include tablet{
        width: 40%;
       }
       @include desktop{
        width: 2vw;
       }
    }

    &__icons{
        width:20%;
        cursor: pointer;
        
        @include tablet{
            width: 15%;
        }
        @include desktop{
            width: 2vw;
        }

        &--login{
            @include tablet{
                width: 5%;
            }
            @include desktop{
                width: 22%;
            }
        }
    }

}

.noDataPage{
    @include mobile{
        height: 80vh;
    }
    @include tablet{
        height: 72vh;
    }
    @include desktop{
        display: flex;
        flex-direction: row;
    }
    &__main{
        width: 33%;
        background-color: $ligh-grey;
        border-radius: 24px;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
        text-align: center;
        display: flex;
        align-items: center;
        margin: 3rem auto auto auto;
        flex-direction: column;
        gap: 1rem;
        @include mobile{
            height: 37vh;
            width: 80%;
        }
        @include tablet{
            height: 30vh;
        }
        @include desktop{
            height: 25vh;
            margin: 15rem auto auto auto;
        }
        padding-top: 1rem;
    }
  

    &__message{
        font-size: 24px;
        font-family:'Montserrat-bold';
    }
    &__icon{
        width: 10%;
    }
    &__link{
        font-size: 15px;
        font-family:'Montserrat-bold';
    }
}