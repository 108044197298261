@use 'variables' as *;

@mixin mobile{
    @media (max-width: $tablet-breakpoint){
        @content;
    }
}

@mixin tablet{
    @media (min-width: $tablet-breakpoint) and (max-width: $desktop-breakpoint){
        @content;
    }
}

@mixin desktop{
    @media (min-width: $desktop-breakpoint) {
        @content;
    }
}